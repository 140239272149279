import { PermissionID, PermissionsRequest, PermissionsResult } from '../../types/Permissions.types';
import { Str } from '../../types/types.types';
import { getSystemNotificationsPermissions } from '../../utils/notifications';
import { toRecord } from '../../utils/record';
import FirebaseSource from '../source/FirebaseSource';

export default class PermissionsRepo {
    constructor(readonly firebaseSource: FirebaseSource) {}

    private resolve?: () => void;
    private reject?: (e: Error) => void;

    private requester: Str;
    private request?: PermissionsRequest;
    private result: Partial<Record<PermissionID, boolean>> = {};

    updateResults = (newResults: Partial<Record<PermissionID, boolean | undefined>>) => {
        const systemResults = getSystemPermissions() as Partial<Record<PermissionID, boolean>>;
        this.result = { ...this.result, ...newResults, ...systemResults };
        this.resolveOrReject();
    };

    clearAll = () => {
        this.resolve = undefined;
        this.reject = undefined;
    };

    resolveOrReject = () => {
        const req = this.request;
        if (!req) return;

        const requested = Object.keys(req).map((it) => it as PermissionID);

        const reqDenied = requested.find((id) => this.result[id] === false && req[id] === 'required');

        if (reqDenied) {
            this.reject?.(new Error(`Required permission was denied: ${reqDenied}`));
            this.clearAll();
            return;
        }

        const pending = requested.find((id) => this.result[id] === undefined);
        if (pending) return;

        this.resolve?.();
        this.clearAll();
    };

    getPermissionsResult = async (): Promise<PermissionsResult[]> => {
        const req = this.request;
        if (!req) return [];

        const prev = await this.firebaseSource.getPermissions(this.requester);

        this.updateResults(toUndef(prev));

        const currents = Object.keys(req)
            .map((it) => it as PermissionID)
            .map((id) => ({ id, level: req[id], result: this.result[id] } as PermissionsResult));

        return currents;
    };

    setPermissionsRequest = async (requester: Str, request: PermissionsRequest): Promise<void> => {
        if (this.reject) this.reject(new Error('Request changed.'));

        this.clearAll();
        if (requester !== this.requester) this.result = {};

        this.requester = requester;
        this.request = request;

        Object.keys(request).forEach((it) => {
            if (false === this.result[it as PermissionID]) {
                delete this.result[it as PermissionID];
            }
        });

        const promise = new Promise<void>((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });

        return promise;
    };

    cancelPermissionsRequest = () => {
        this.reject?.(new Error('Request cancelled.'));
        this.clearAll();
    };

    setDenyPermissions = async (id: PermissionID) => {
        const req = this.request;
        if (!req) return;
        this.updateResults({ [id]: false });
    };

    setAllowPermissions = async (id: PermissionID) => {
        const req = this.request;
        if (!req) return;
        await this.firebaseSource.setAllowPermissions(this.requester, id);
        this.updateResults({ [id]: true });
    };
}

const toUndef = (
    r: Partial<Record<PermissionID, boolean | null | undefined>>
): Partial<Record<PermissionID, boolean | undefined>> => {
    return toRecord(Object.keys(r) as PermissionID[], (it) => [
        it,
        (r[it] === null ? undefined : r[it]) as boolean | undefined,
    ]);
};

const getSystemPermissions = (): Partial<Record<PermissionID, boolean>> => {
    const device = getSystemNotificationsPermissions();

    let r = {};
    if (device === 'invalid' || device === 'denied') {
        r = { ...r, webnotif: false };
    }
    return r;
};
