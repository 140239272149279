import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PropsWithChildren } from 'react';

const queryOptions = {
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false, // default: true
            retry: false,
        },
    },
};

const client = new QueryClient(queryOptions);

const QueryProvider = ({ children }: PropsWithChildren) => {
    return <QueryClientProvider client={client}>{children}</QueryClientProvider>;
};

export const TestQueryProvider = ({ children }: PropsWithChildren) => {
    return <QueryClientProvider client={new QueryClient(queryOptions)}>{children}</QueryClientProvider>;
};

export default QueryProvider;
