import { Collector, IEmit, IFlow } from "./IFlow";

class Flow<Type> implements IFlow<Type>, IEmit<Type> {
    private _data: Type;
    private _collectors: Collector<Type>[];
    private  _onceCollectors: Collector<Type>[];

    constructor(initial: Type){
        this._data = initial;
        this._collectors = [];
        this._onceCollectors = [];
    }

    collect = (collector: Collector<Type>) => {
        this._collectors.push(collector);
    };

    collectOnce = (collector: Collector<Type>) => {
        this._onceCollectors.push(collector);
    };

    disconnect = (collector: Collector<Type>) => {
        this._collectors = this._collectors.filter(it => it !== collector);
        this._onceCollectors = this._onceCollectors.filter(it => it !== collector);
    };

    emit = (data: Type) => {
        this._data = data;
        this._collectors.forEach(collector => collector(this._data));
        this._onceCollectors.forEach(collector => collector(this._data));
        this._onceCollectors.splice(0);
    };

    data = () => this._data;
}

export default Flow;