import User from '../../domain/auth/User';
import { FileInfo } from '../../types/FileInfo.types';
import { resizeImgUrl, resizeVideoUrl } from '../../ui/uiutils/image';
import { getFileData, getFileOrUrlType } from '../../utils/file';
import { generateNameFromFile } from '../../utils/url';
import FirebaseSource from '../source/FirebaseSource';

const THUMB_SIZE = 256;

export default class StoryRepo {
    getCurrentUser: () => Promise<User | undefined>;
    firebaseSource: FirebaseSource;

    constructor(firebaseSource: FirebaseSource, getCurrentUser: () => Promise<User | undefined>) {
        this.getCurrentUser = getCurrentUser;
        this.firebaseSource = firebaseSource;
    }

    upload = async ({ file, url, size, name, clip }: FileInfo) => {
        if (!file) return url;
        const user = await this.getCurrentUser();
        if (!user) throw new Error('Must login to upload files');
        const path = `/story/${user?.id}/${generateNameFromFile(name ?? file.name ?? 'file')}`;
        const fileData = await getFileData(file, url, size === 'small', clip);
        return await this.firebaseSource.uploadFile(path, fileData);
    };

    uploadThumbnail = async ({ file, url, name }: FileInfo) => {
        const user = await this.getCurrentUser();
        if (!user) throw new Error('Must login to upload files');
        const path = `/story/${user?.id}/${generateNameFromFile(name ?? file?.name ?? 'file', 'jpg')}`;
        const type = getFileOrUrlType(url, file);
        const fileData = type === 'video' ? await resizeVideoUrl(url, THUMB_SIZE) : await resizeImgUrl(url, THUMB_SIZE);
        return await this.firebaseSource.uploadFile(path, fileData);
    };
}
