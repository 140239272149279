import { createTheme } from '@mui/material';
import './styler.css';

const common = createTheme({
    typography: {
        fontFamily: ['Open Sans', '-apple-system', 'Roboto', 'Arial'].join(',')
    }
});

const lightTheme = createTheme({
    ...common,
    palette: {
        mode: 'light',
        primary: {
            main: 'rgba(100, 75, 150, 255)',
            contrastText: '#ffffff'
        },
        secondary: {
            main: 'rgba(100, 15, 150, 255)',
            contrastText: '#ffffff'
        },
        text: {
            primary: '#555',
            secondary: '#777',
            disabled: '#999'
        },
        background: {
            default: '#fefefe',
            paper: '#f6f6f6'
        }
    }
});

const darkTheme = createTheme({
    ...common,
    palette: {
        mode: 'dark',
        primary: {
            main: 'rgba(150, 100, 200, 255)',
            contrastText: '#fff'
        },
        secondary: {
            main: 'rgba(150, 100, 200, 255)',
            contrastText: '#fff'
        },
        text: {
            primary: '#e0e0e0',
            secondary: '#c0c0c0',
            disabled: '#a0a0a0'
        },
        background: {
            default: '#1f1f1f',
            paper: '#0f0f0f'
        }
    }
});

// let-510 disabling light theme
export { lightTheme, darkTheme };
