import FirebaseSource from '../../data/source/FirebaseSource';

export const messagingProviders = {
    sendMessage: {
        provider: ({ firebaseSource }: { firebaseSource: FirebaseSource }) => firebaseSource.sendMessage,
        single: false,
    },
    getChats: {
        provider: ({ firebaseSource }: { firebaseSource: FirebaseSource }) => firebaseSource.getChats,
        single: false,
    },
    getMessages: {
        provider: ({ firebaseSource }: { firebaseSource: FirebaseSource }) => firebaseSource.getMessages,
        single: false,
    },
};
