import FilesRepo from '../../data/files/FilesRepo';
import FirebaseSource from '../../data/source/FirebaseSource';
import User from '../../domain/auth/User';

export const filesProviders = {
    upload: {
        provider: ({ filesRepo }: { filesRepo: FilesRepo }) => filesRepo.upload,
        single: false,
    },
    uploadThumbnail: {
        provider: ({ filesRepo }: { filesRepo: FilesRepo }) => filesRepo.uploadThumbnail,
        single: false,
    },
    filesRepo: {
        provider: ({
            firebaseSource,
            getCurrentUser,
        }: {
            firebaseSource: FirebaseSource;
            getCurrentUser: () => Promise<User | undefined>;
        }) => new FilesRepo(firebaseSource, getCurrentUser),
        single: true,
    },
};
