import { DeviceNotificationsStatus } from '../types/Notifications.type';

export const getSystemNotificationsPermissions = (): DeviceNotificationsStatus => {
    if (!('Notification' in window)) {
        // Check if the browser supports notifications
        return 'invalid';
    } else if (Notification.permission === 'granted') {
        // Check whether notification permissions have already been granted;
        // if so, create a notification
        return 'granted';
    } else if (Notification.permission === 'denied') {
        // We need to ask the user for permission
        return 'denied';
    } else if (Notification.permission === 'default') {
        return 'unknown';
    }
    return 'invalid';
};

export const requestNotifications = async () => {
    if (!window.Notification) throw new Error('Notifications not supported');
    return await Notification.requestPermission();
};
