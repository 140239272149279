const injectorSingletons: Record<string, any> = {};

const injector = (factory: Record<string, InjectorFactory>) => {
    const injectorObject: Record<string, any> = {};
    const searchPath: Array<string> = [];

    Object.entries(factory).forEach(([name, { single, provider }]) => {

        if (name in injectorObject) {
            console.warn(`'${name}' key already in the factory. Overriding the current configuration.`);
        }

        Object.defineProperty(
            injectorObject,
            name,
            {
                get: () => {
                    if (searchPath.find(path => path === name)) {
                        throw new Error(`Key ${name} has a circular reference dependency. ${searchPath.join(' -> ')} -> ${name}`);
                    }

                    if (single && name in injectorSingletons) return injectorSingletons[name];

                    searchPath.push(name);
                    const r = provider(injectorObject);
                    searchPath.pop();

                    if (single) injectorSingletons[name] = r;

                    return r;
                }
            }
        );

    });

    return injectorObject;
};

export interface InjectorFactory {
    provider: (props: any) => Record<string, any>;
    single: boolean;
}

export { injector, injectorSingletons }
