import { resizeImgUrl, transformImgUrl } from '../ui/uiutils/image';
import { Mat3x3 } from './mat3x3';

export type FileType = 'image' | 'video';
export type FileClip = {
    aspect: number;
    H: Mat3x3;
};

export const getFileData = async (file: File, url: string, small: boolean, clip?: FileClip): Promise<File | Blob> => {
    if (getFileType(file) !== 'image') return file;

    const size = small ? 516 : 1920;

    if (!clip) {
        const skip = small ? 300 : 900;
        if (file.size / 1000 < skip) return file;
        return await resizeImgUrl(url, size);
    } else {
        return await transformImgUrl(url, clip, size);
    }
};

export const getFileType = (file: File): FileType | undefined => {
    if (file.type.indexOf('video') >= 0) {
        return 'video';
    } else if (file.type.indexOf('image') >= 0) {
        return 'image';
    }
};

export const getFileOrUrlType = (url: string, file: File | undefined): FileType | undefined => {
    if (file) {
        return getFileType(file);
    } else {
        if (hasVideoExtension(url)) {
            return 'video';
        } else return 'image';
    }
};

const hasVideoExtension = (url: string): boolean => {
    const supported = ['.mp4', '.mov', '.webm', '.mkv'];
    for (const idx in supported) if (url.toLowerCase().indexOf(supported[idx]) > 0) return true;
    return false;
};
