import firebase from 'firebase/compat/app';
import {
    Auth,
    User as FirebaseUser,
    getAuth,
    onAuthStateChanged,
    signInAnonymously,
    signInWithCustomToken,
    signOut,
    updateProfile,
} from 'firebase/auth';
import {
    Firestore,
    getFirestore,
    getDocs,
    collection,
    query,
    addDoc,
    doc,
    setDoc,
    QueryDocumentSnapshot,
    getDoc,
    DocumentSnapshot,
    deleteDoc,
    where,
} from 'firebase/firestore';
import { httpsCallable, getFunctions } from 'firebase/functions';

import IFirebaseDocument from './FirebaseDocument';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import Flow from '../../ui/arch/Flow';
import { IFirebaseRecord } from '../types/IFirebaseRecord.types';
import User from '../../domain/auth/User';
import { Chat, Message } from '../../domain/chats/Message.types';
import { Reminder } from '../../domain/sked/Reminder.types';
import { Feedback } from '../../domain/story/Feedback.types';
import { map } from '../../utils/map';
import { MyStory, StoryCodeInfo } from '../../types/Story.type';
import { MyTP } from '../../domain/reports/MyTP.types';
import { PermissionID, PermissionsResultRecord } from '../../types/Permissions.types';
import { Str } from '../../types/types.types';
import { Credit, CreditType } from '../../types/Credit.types';
import { ID } from '../../types/ID.types';

const STORAGE_BASE = 'https://storage.googleapis.com';

const firebaseConfig = {
    apiKey: 'AIzaSyBzUFGsZyXnd6oAefE-i_IukI8Ik28H3Wk',
    authDomain: 'mille-lenstour.firebaseapp.com',
    databaseURL: 'https://mille-lenstour-default-rtdb.firebaseio.com',
    projectId: 'mille-lenstour',
    storageBucket: 'mille-lenstour.appspot.com',
    messagingSenderId: '1086641762144',
    appId: '1:1086641762144:web:4709a16a8f1116dd7db805',
    measurementId: 'G-WENMZ77DD9',
};

class FirebaseSource {
    private app: firebase.app.App;
    private auth: Auth;
    private db: Firestore;
    private phoneRequestInfo?: PhoneRequestInfo;
    private emailRequestInfo?: EmailRequestInfo;

    userIdFlow: Flow<string | undefined>;

    constructor() {
        this.app = firebase.initializeApp(firebaseConfig);
        this.auth = getAuth(this.app);
        this.db = getFirestore(this.app);
        this.userIdFlow = new Flow<string | undefined>(undefined);
    }

    generateId = (path: string) => {
        return doc(collection(this.db, path)).id;
    };

    getList = async <Item>(path: string, mapper: (record: IFirebaseRecord) => Item): Promise<Item[]> => {
        const q = query(collection(this.db, path));
        const snapShot = await getDocs(q);
        const result = new Array<Item>();

        snapShot.forEach((it) => {
            result.push(mapper({ ...it.data(), id: it.id }));
        });

        return result;
    };

    getItem = async <Item>(path: string, id: string, mapper: (record: IFirebaseRecord) => Item): Promise<Item> => {
        const ref = doc(this.db, path, id);
        const snap = await getDoc(ref);
        if (!snap.exists()) {
            throw Error(`Requested document does not exist. Id ${id}`);
        }
        return mapper({ id: snap.id, ...snap.data() });
    };

    deleteDocument = async (path: string, id: string) => {
        await deleteDoc(doc(this.db, path, id));
    };

    getCurrentUser = async () => map(await authorize(this.auth), (it) => toUser(it));

    getUser = async () => {
        return toUser((await authorize(this.auth)) ?? (await signInAnonymously(this.auth)).user);
    };

    getDocuments = async <Document extends IFirebaseDocument>(path: string, filters?: DocumentsFilter[]) => {
        const wheres = filters?.map(({ field, oper, value }) => where(field, oper, value)) ?? [];
        const q = query(collection(this.db, path), ...wheres);
        const snapShot = await getDocs(q);
        const result = new Array<Document>();

        snapShot.forEach((it) => {
            result.push(toFirebaseDocument(it));
        });

        return result;
    };

    getDocument = async <Document extends IFirebaseDocument>(path: string, docId: string) => {
        const ref = doc(this.db, path, docId);
        const snap = await getDoc(ref);
        if (!snap.exists()) {
            throw Error(`Requested document does not exist. Id ${docId}`);
        }
        return toFirebaseDocument<Document>(snap);
    };

    getDocumentOrNull = async <Document extends IFirebaseDocument | null>(path: string, docId: string) => {
        const ref = doc(this.db, path, docId);
        const snap = await getDoc(ref);
        if (!snap.exists()) return null;
        return toFirebaseDocument<Document>(snap);
    };

    createDocument = async (path: string, data: any) => {
        var ref = await addDoc(collection(this.db, path), cleanDoc(data));
        return ref.id;
    };

    updateDocument = async (path: string, storyId: string, data: any) => {
        const docRef = doc(this.db, path, storyId);
        await setDoc(docRef, cleanDoc(data), { merge: true });
    };

    signInAnonymously = async () => {
        await signInAnonymously(this.auth);
    };

    uploadFile = async (path: string, data: File | Blob | Uint8Array) => {
        const storage = getStorage();
        const fileRef = ref(storage, path);
        await uploadBytes(fileRef, data);
        return `${STORAGE_BASE}/${firebaseConfig.storageBucket}${path}`;
    };

    submitPhone = async (phone: string) => {
        const { requestId } = await this.call<{ requestId: string }>('sendSmsCode', { phone });
        this.phoneRequestInfo = { requestId, phone };
    };

    submitCode = async (code: string) => {
        const request = {
            phone: this.phoneRequestInfo?.phone,
            requestId: this.phoneRequestInfo?.requestId,
            code,
        };

        const { token } = await this.call<VerifyCodeResult>('verifySmsCode', request);

        await this.signInWithToken(token);
    };

    submitEmail = async (email: string) => {
        const { requestId } = await this.call<{ requestId: string }>('sendEmailCode', { email });
        this.emailRequestInfo = { requestId, email };
    };

    submitEmailCode = async (code: string) => {
        const request = {
            email: this.emailRequestInfo?.email,
            requestId: this.emailRequestInfo?.requestId,
            code,
        };

        const { token } = await this.call<VerifyCodeResult>('verifyEmailCode', request);

        await this.signInWithToken(token);
    };

    call = async <T>(fnName: string, args: object): Promise<T> => {
        const calledfn = httpsCallable(getFunctions(), fnName);
        const r = await calledfn(args);

        if (r?.data) {
            const error = (r.data as { error?: string }).error;
            if (error) throw new Error(error);
        }

        return r?.data as T;
    };

    private signInWithToken = async (token: string) => {
        await signInWithCustomToken(this.auth, token);
    };

    signOut = async () => {
        await signOut(this.auth);
    };

    addTp = async (storyId: string, tp: string) => {
        await this.call('addTp', { storyId, tp, source: 'web' });
    };

    addMyStory = async (storyId: string) => {
        const ADDED = 'added-stories';
        const added = window.sessionStorage.getItem(ADDED)?.split(',') ?? [];

        if (added.find((it) => it === storyId)) return;

        await this.getUser();
        await this.call('addMyStory', { storyId, source: 'web' });

        added.push(storyId);
        window.sessionStorage.setItem(ADDED, added.join(','));
    };

    removeMyStory = async (storyId: string) => {
        const user = await this.getUser();
        await this.deleteDocument(`my/${user.id}/stories`, storyId);
    };

    resetMyStoryChats = async (storyId: string) => {
        const user = await this.getUser();
        await this.updateDocument(`my/${user.id}/stories`, storyId, { chatCount: 0 });
    };

    sendMessage = async (toId: string, message: Message) => {
        await this.call('sendMessage', { recipientId: toId, message: message.text, url: message.url });
    };

    getChats = async () => {
        const user = await this.getCurrentUser();
        if (!user) throw new Error('Must be signed in to get messages');
        return await this.getDocuments<Chat>(`my/${user.id}/dms`);
    };

    setReminder = async (reminder: Reminder) => {
        const { time, ownerId, title, url, id } = reminder;
        const userId = (await this.getUser()).id;
        const path = `my/${userId}/reminders`;
        const docRef = doc(this.db, path, id);
        await setDoc(docRef, { date: time, ownerId, title, url });
    };

    sendFeedback = async (feedback: Feedback) => {
        await this.getUser();
        await this.call('sendFeedback', feedback);
    };

    getStoryCode = async (code: string): Promise<StoryCodeInfo | null> => {
        return await this.getDocumentOrNull<StoryCodeInfo>(`code`, code);
    };

    setStoryCode = async (code: string, storyId: string): Promise<void> => {
        const userId = (await this.getCurrentUser())?.id;
        if (!userId) throw new Error('Login required for this action');
        const time = new Date().getTime();
        await this.updateDocument(`code`, code, { storyId, userId, time });
    };

    getMyStories = async (): Promise<MyStory[]> => {
        const user = await this.getCurrentUser();
        if (!user) return [];
        return await this.getDocuments<MyStory>(`my/${user?.id}/stories`);
    };

    getMyStory = async (id: string): Promise<MyStory | null> => {
        const user = await this.getCurrentUser();
        if (!user) return null;
        return await this.getDocument<MyStory>(`my/${user?.id}/stories`, id);
    };

    getMyTps = async (minTime: number): Promise<MyTP[]> => {
        const user = await this.getCurrentUser();
        if (!user) return [];
        return await this.getDocuments<MyTP & { id: string }>(`my/${user?.id}/tps`, [
            { field: 'time', oper: '>', value: minTime },
        ]);
    };

    getPermissions = async (requester?: string | null): Promise<Partial<PermissionsResultRecord>> => {
        const user = await this.getCurrentUser();
        if (!user) return {};
        const web = await this.call<PermissionsResultRecord>('getPermissions', { requester });
        return web;
    };

    setAllowPermissions = async (requester: Str, permissionId: PermissionID, granted: boolean = true) => {
        const user = await this.getCurrentUser();
        if (!user) throw new Error('Cannot allow permissions by a guest user');
        await this.call('setPermissions', { requester, permissionId, granted });
    };

    updateName = async (displayName: string) => {
        await this.getUser();
        await updateProfile(this.auth.currentUser!, { displayName });
    };

    addCredit = async (type: CreditType) => {
        await this.call('addCredit', { type });
    };

    getCredits = async (): Promise<Credit[]> => {
        const user = await this.getCurrentUser();
        if (!user) return [];
        return await this.getDocuments<Credit & { id: string }>(`my/${user?.id}/credits`);
    };

    getMessages = async (senderId: string) => {
        const user = await this.getCurrentUser();
        if (!user) return [];
        return await this.getDocuments<Message & ID>(`my/${user.id}/dms/${senderId}/messages`);
    };

    signInWithWebOtp = async (code: string) => {
        const customToken = await this.call<string>('getOtpCustomToken', { code });
        signInWithCustomToken(this.auth, customToken);
    };
}

const authorize = async (auth: Auth): Promise<FirebaseUser | null> => {
    return await new Promise((resolve) => {
        onAuthStateChanged(auth, (user) => {
            resolve(user);
        });
    });
};

const toFirebaseDocument = <Document>(doc: QueryDocumentSnapshot | DocumentSnapshot): Document => {
    return { id: doc.id, ...doc.data() } as Document;
};

const cleanDoc = (doc: any, level: number = 0) => {
    if (!doc) return;
    for (const s in doc) {
        if (doc[s] === undefined) {
            delete doc[s];
        } else if (typeof doc[s] === 'object') {
            cleanDoc(doc[s], level + 1);
        }
    }
    if (doc.hasOwnProperty('id') && level < 1) delete doc.id;
    return doc;
};

const toUser = (fbUser: FirebaseUser): User => {
    return {
        name: fbUser.displayName,
        email: fbUser.email,
        id: fbUser.uid,
        phone: fbUser.phoneNumber,
        isAnonymous: fbUser.isAnonymous,
        emailVerified: fbUser.emailVerified,
    };
};

export type DocumentsFilter = {
    field: string;
    oper: DocumentsFilterOperation;
    value: string | number | boolean;
};

type VerifyCodeResult = { userId: string; token: string };

type PhoneRequestInfo = { phone: string; requestId: string };

type EmailRequestInfo = { email: string; requestId: string };

export type DocumentsFilterOperation = '==' | '>' | '<' | '>=' | '<=';

export default FirebaseSource;
