import { PromoRepo } from "../../data/promos/PromoRepo";
import { RepoProviderProps } from "./lensinjector";

export const prizeProviders = {
    promoRepo: {
        provider: ({ firebaseSource, getUserId, getIsGuest }: RepoProviderProps) =>
            new PromoRepo(firebaseSource, getUserId, getIsGuest),
        single: true,
    },
    getPromos: {
        provider: ({ promoRepo }: PromoRepoProps) => promoRepo.getPromos,
        single: false,
    },
    createPromo: {
        provider: ({ promoRepo }: PromoRepoProps) => promoRepo.createPromo,
        single: false,
    },
    updatePromo: {
        provider: ({ promoRepo }: PromoRepoProps) => promoRepo.updatePromo,
        single: false,
    },
    getPromo: {
        provider: ({ promoRepo }: PromoRepoProps) => promoRepo.getPromo,
        single: false,
    },
    deletePromotion: {
        provider: ({ promoRepo }: PromoRepoProps) => promoRepo.deletePromotion,
        single: false,
    },
    savePrize: {
        provider: ({ promoRepo }: PromoRepoProps) => promoRepo.savePrize,
        single: false,
    },
    getPrizes: {
        provider: ({ promoRepo }: PromoRepoProps) => promoRepo.getPrizes,
        single: false,
    },
    getPrize: {
        provider: ({ promoRepo }: PromoRepoProps) => promoRepo.getPrize,
        single: false,
    },
    deletePrize: {
        provider: ({ promoRepo }: PromoRepoProps) => promoRepo.deletePrize,
        single: false,
    },
    getEntries: {
        provider: ({ promoRepo }: PromoRepoProps) => promoRepo.getEntries,
        single: false,
    },
};

interface PromoRepoProps {
    promoRepo: PromoRepo;
}
