import { Prize } from '../../domain/promos/Prize.types';
import Promo from '../../domain/promos/Promo.type';
import FirebaseSource from '../source/FirebaseSource';
import { IFirebaseRecord } from '../types/IFirebaseRecord.types';

class PromoRepo {
    constructor(
        readonly firebaseSource: FirebaseSource,
        readonly getUserId: () => Promise<string | undefined>,
        readonly getIsGuest: () => Promise<boolean>
    ) {}

    getPromos = async () => {
        const userId = await this.getUserId();
        if (!userId) return [];
        return await this.firebaseSource.getList(`promos/${userId}/promo`, toPromo);
    };

    createPromo = async (p: Promo) => {
        const userId = await this.getUserId();
        const isGuest = await this.getIsGuest();
        if (isGuest) throw new Error('You must sign in to create a promotion.');
        return await this.firebaseSource.createDocument(`promos/${userId}/promo`, p);
    };

    updatePromo = async (id: string, p: Promo) => {
        const userId = await this.getUserId();
        return await this.firebaseSource.updateDocument(`promos/${userId}/promo`, id, p);
    };

    getPromo = async (id: string) => {
        const userId = await this.getUserId();
        try {
            return await this.firebaseSource.getItem(`promos/${userId}/promo`, id, toPromo);
        } catch (e: unknown) {
            throw new Error('Unable to load the Promotion');
        }
    };

    deletePromotion = async (id: string) => {
        const userId = await this.getUserId();
        try {
            return await this.firebaseSource.deleteDocument(`promos/${userId}/promo`, id);
        } catch (e: unknown) {
            throw new Error('Unable to delete the Promotion');
        }
    };

    savePrize = async (promoId: string, prize: Prize) => {
        const userId = await this.getUserId();
        if (!!prize.id) {
            return await this.firebaseSource.updateDocument(
                `promos/${userId}/promo/${promoId}/prizes`,
                prize.id,
                prize
            );
        } else {
            return await this.firebaseSource.createDocument(`promos/${userId}/promo/${promoId}/prizes`, prize);
        }
    };

    getPrizes = async (promoId: string) => {
        const userId = await this.getUserId();
        return await this.firebaseSource.getList(`promos/${userId}/promo/${promoId}/prizes`, toPrize);
    };

    getPrize = async (promoId: string, id: string) => {
        const userId = await this.getUserId();
        return await this.firebaseSource.getItem(`promos/${userId}/promo/${promoId}/prizes`, id, toPrize);
    };

    deletePrize = async (promoId: string, id: string) => {
        const userId = await this.getUserId();
        return await this.firebaseSource.deleteDocument(`promos/${userId}/promo/${promoId}/prizes`, id);
    };

    getEntries = async (promoId: string, prizeId: string) => {
        const userId = await this.getUserId();
        return await this.firebaseSource.getList(
            `promos/${userId}/promo/${promoId}/prizes/${prizeId}/entries`,
            toEntry
        );
    };
}

const toEntry = (doc: IFirebaseRecord): Entry => ({
    id: doc.id,
    time: doc.time,
    won: !!doc.won,
    name: doc.name,
    userId: doc.userId,
});

const toPromo = (doc: IFirebaseRecord): Promo => {
    return {
        id: doc.id,
        name: doc.name,
        end: doc.end || 0,
        start: doc.start || 0,
        disabled: doc.disabled,
    };
};

const toPrize = (doc: IFirebaseRecord): Prize => ({ ...doc } as Prize);

export { PromoRepo };
