import Category from '../../domain/manager/models/Category';
import { Story, StoryDetail } from '../../types/Story.type';
import { Prop } from '../../domain/manager/models/props/Prop.types';
import { time } from '../../utils/time';
import { generateNameFromFile } from '../../utils/url';
import IFirebaseDocument from '../source/FirebaseDocument';
import FirebaseSource from '../source/FirebaseSource';

const MANIFEST_PATH = `lens`;
const MANIFEST_ID = 'v2';

export default class StoryRepo {
    getUserId: () => Promise<string | undefined>;
    getAnonId: () => Promise<string | undefined>;
    firebaseSource: FirebaseSource;

    constructor(
        firebaseSource: FirebaseSource,
        getUserId: () => Promise<string | undefined>,
        getAnonId: () => Promise<string | undefined>
    ) {
        this.getUserId = getUserId;
        this.getAnonId = getAnonId;
        this.firebaseSource = firebaseSource;
    }

    getPath = async () => `${MANIFEST_PATH}/${MANIFEST_ID}/users/${await this.getUserId()}/stories`;

    getStories = async () => {
        const userId = await this.getUserId();
        if (!userId) return [];

        return (await this.firebaseSource.getDocuments<IFirebaseDocument>(await this.getPath())).map((it) =>
            toStory(it)
        );
    };

    getStory = async (storyId: string) => {
        const ownerId = storyId.split('.')[0];
        const path = `${MANIFEST_PATH}/${MANIFEST_ID}/users/${ownerId}/stories`;
        return toStory(await this.firebaseSource.getDocument<IFirebaseDocument>(path, storyId));
    };

    getCategories = async () => {
        const docs = await this.firebaseSource.getDocuments<IFirebaseDocument>(`lens/tour/categories`);
        return docs.map((it) => toCategory(it));
    };

    createStory = async (detail: StoryDetail) => {
        let userId = await this.getUserId();
        if (!userId) userId = await this.getAnonId();
        const newId = this.firebaseSource.generateId(await this.getPath());
        const id = `${userId}.${newId}`;
        await this.updateStory({ ...detail, id, create: time() } as unknown as Story);
        return id;
    };

    updateStory = async (story: Story) => {
        const userId = await this.getUserId();
        await this.firebaseSource.updateDocument(await this.getPath(), story.id, {
            ...story,
            time: time(),
            disabled: story.disabled ?? false,
            userId,
            buildUrl: undefined,
        });

        await this.firebaseSource.call('setIsOwner', {});

        if (story.targetUrl) {
            const force = window.sessionStorage.getItem('forceRebuilds') === 'yes';
            await this.firebaseSource.call('buildTarget', { storyId: story.id, force });
        }
    };

    updateManifest = async () => {
        await this.firebaseSource.updateDocument(MANIFEST_PATH, MANIFEST_ID, { time: time() });
    };

    uploadFile = async (name: string, data: File | Blob | Uint8Array) => {
        const userId = await this.getUserId();
        const path = `/story/${userId}/${generateNameFromFile(name)}`;
        return await this.firebaseSource.uploadFile(path, data);
    };

    deleteStory = async (id: string) => {
        await this.firebaseSource.deleteDocument(await this.getPath(), id);
    };
}

const toCategory = (document: any) =>
    ({
        id: document.id,
        name: document.name,
        closed: document.closed,
    } as Category);

function toStory(document: any): Story {
    return {
        id: document.id,
        time: document.time ?? 0,
        name: document.name,
        description: document.description,
        categoryId: document.categoryId,
        thumbUrl: document.thumbUrl ?? '',
        props: document.props?.map((it: any) => toProp(it)) ?? [],
        published: document.published,
        targetUrl: document.targetUrl,
        ...document,
    };
}

function toProp(prop: any): Prop {
    return prop as Prop;
}
