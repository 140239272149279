import { Point } from './math';

export type Mat3x3Row = [number, number, number];

export type Mat3x3 = [Mat3x3Row, Mat3x3Row, Mat3x3Row];

export type MatMxN = number[][];

export const identityH = (): Mat3x3 => [
    [1, 0, 0],
    [0, 1, 0],
    [0, 0, 1],
];

export const getHomography = (src: Point[], dest: Point[]): Mat3x3 => {
    if (src.length !== dest.length)
        throw new Error('Points count mismatch. Source has ' + src.length + ' dest has ' + dest.length + ' elements.');

    if (src.length < 4) throw new Error('Minimum 4 points needed.');

    const A = _getHomographyMatA(src, dest);
    const B = getHomographyMatB(dest);

    const f8 = solve(A, B);

    return [
        [f8[0], f8[1], f8[2]],
        [f8[3], f8[4], f8[5]],
        [f8[6], f8[7], 1],
    ];
};

export const matMxN = (rows: number, cols: number): MatMxN => {
    return Array(rows)
        .fill(0)
        .map(() => Array(cols).fill(0));
};

const _getHomographyMatA = (src: Point[], dest: Point[]) => {
    const p = matMxN(src.length * 2, 8);

    for (let i = 0, r = 0; i < src.length; i++, r += 2) {
        p[r][0] = -src[i].x;
        p[r][1] = -src[i].y;
        p[r][2] = -1;
        /* defaults to 0 anyways
                p[r, 3] = 0;
                p[r, 4] = 0;
                p[r, 5] = 0;
                */
        p[r][6] = src[i].x * dest[i].x;
        p[r][7] = src[i].y * dest[i].x;

        const rp1 = r + 1;
        /* defaults to 0 anyways
                p[rp1, 0] = 0;
                p[rp1, 1] = 0;
                p[rp1, 2] = 0;
                */
        p[rp1][3] = -src[i].x;
        p[rp1][4] = -src[i].y;
        p[rp1][5] = -1;
        p[rp1][6] = src[i].x * dest[i].y;
        p[rp1][7] = src[i].y * dest[i].y;
    }

    return p;
};

export const getHomographyMatB = (dest: Point[]): MatMxN => {
    const M = matMxN(dest.length * 2, 1);

    for (let i = 0, r = 0; i < dest.length; i++, r += 2) {
        M[r][0] = -dest[i].x;

        const rp1 = r + 1;
        M[rp1][0] = -dest[i].y;
    }

    return M;
};

const rows = (M: MatMxN): number => M.length;

const cols = (M: MatMxN): number => M[0].length;

const transpose = (M: MatMxN): MatMxN => {
    const T = matMxN(cols(M), rows(M));
    const numRows = rows(M);
    const numCols = cols(M);

    for (let r = 0; r < numRows; r++) {
        for (let c = 0; c < numCols; c++) {
            T[c][r] = M[r][c];
        }
    }
    return T;
};

export const mulH = (H: Mat3x3, pt: Point): Point => {
    const tz = H[2][0] * pt.x + H[2][1] * pt.y + 1;

    return {
        x: (H[0][0] * pt.x + H[0][1] * pt.y + H[0][2]) / tz,
        y: (H[1][0] * pt.x + H[1][1] * pt.y + H[1][2]) / tz,
    };
};

const mulHxH = (A: MatMxN, B: MatMxN): MatMxN => {
    if (cols(A) !== rows(B)) throw new Error('The new matrices cannot be multiplied A.m != B.n');

    const result = matMxN(rows(A), cols(B));

    for (let i = 0; i < rows(A); i++)
        for (let j = 0; j < cols(B); j++) for (let k = 0; k < cols(A); k++) result[i][j] += A[i][k] * B[k][j];

    return result;
};

const solve = (A: MatMxN, B: MatMxN) => {
    if (rows(A) !== rows(B)) throw new Error('A and B rows must be equal.');

    if (rows(A) < cols(A)) throw new Error(`Matrix is underdetermined. ${rows(A)}x${cols(A)}`);

    if (rows(A) > cols(A)) {
        // overdetermined. least square.
        const T = transpose(A);
        B = mulHxH(T, B);
        A = mulHxH(T, A);

        // now we have least square solution...
    }

    return solveGaussian(A, B);
};

const solveGaussian = (A: MatMxN, B: MatMxN): number[] => {
    // http://en.wikipedia.org/wiki/Gaussian_elimination

    if (cols(A) !== rows(A)) throw new Error('Matrix A must be square.');

    if (rows(A) !== rows(B)) throw new Error('Matrix B rows must equal Matrix A rows. Cannot have more than 1 col.');

    const X = combine(A, B);

    let i = 0;
    let j = 0;
    let n = cols(X);
    let m = n - 1;

    while (i < m && j < n) {
        // Find pivot in column j, starting in row i:
        let maxi = i;
        for (let k = i + 1; k < m; k++) {
            if (Math.abs(X[k][j]) > Math.abs(X[maxi][j])) {
                maxi = k;
            }
        }
        if (Math.abs(X[maxi][j]) > Number.EPSILON) {
            //swap rows i and maxi, but do not change the value of i
            if (i !== maxi)
                for (let k = 0; k < n; k++) {
                    const aux = X[i][k];
                    X[i][k] = X[maxi][k];
                    X[maxi][k] = aux;
                }
            //Now A[i,j] will contain the old value of A[maxi,j].
            //divide each entry in row i by A[i,j]
            const A_ij = X[i][j];
            for (let k = 0; k < n; k++) {
                X[i][k] /= A_ij;
            }
            //Now A[i,j] will have the value 1.
            for (let u = i + 1; u < m; u++) {
                //subtract A[u,j] * row i from row u
                const A_uj = X[u][j];
                for (let k = 0; k < n; k++) {
                    X[u][k] -= A_uj * X[i][k];
                }
                //Now A[u,j] will be 0, since A[u,j] - A[i,j] * A[u,j] = A[u,j] - 1 * A[u,j] = 0.
            }

            i++;
        }
        j++;
    }

    //back substitution
    for (let k = m - 2; k >= 0; k--) {
        for (let l = k + 1; l < n - 1; l++) {
            X[k][m] -= X[k][l] * X[l][m];
            //A[i*n+j]=0;
        }
    }

    return column(X, cols(X) - 1);
};

const combine = (A: MatMxN, B: MatMxN): MatMxN => {
    if (rows(A) !== rows(B))
        throw new Error("Matrices cannot be combined since they don't have teh same number or rows");

    const C = matMxN(rows(A), cols(A) + cols(B));
    const colsA = cols(A);

    for (let r = 0; r < rows(A); r++) {
        for (let c = 0; c < cols(A); c++) C[r][c] = A[r][c];
    }

    for (let r = 0; r < rows(A); r++) {
        for (let c = 0; c < cols(B); c++) C[r][colsA + c] = B[r][c];
    }

    return C;
};

const column = (M: MatMxN, col: number): number[] => {
    return M.map((row) => row[col]);
};

export const duplicateM = (M: MatMxN): MatMxN => M.map((r) => [...r]);

export const inverseMat = (M: MatMxN): MatMxN => {
    if (rows(M) !== cols(M)) throw new Error("Matrix does not have invese because it's not square.");

    var result = duplicateM(M);

    const { perm, lum } = decompose(M);

    const b: number[] = [];
    const nRows = rows(M);

    for (let i = 0; i < nRows; ++i) {
        for (let j = 0; j < nRows; ++j) {
            if (i === perm[j]) b[j] = 1.0;
            else b[j] = 0.0;
        }

        const x = solveLU(lum, b); //

        for (let j = 0; j < nRows; ++j) result[j][i] = x[j];
    }

    return result;
};

export const inverse = (H: Mat3x3) => inverseMat(H) as Mat3x3;

export const decompose = (M: MatMxN): { perm: number[]; lum: MatMxN } => {
    // Doolittle LUP decomposition with partial pivoting.
    // rerturns: result is L (with 1s on diagonal) and U;
    // perm holds row permutations; toggle is +1 or -1 (even or odd)

    if (rows(M) !== cols(M)) throw new Error('Attempt to decompose a non-square m');

    const nRows = rows(M); // convenience

    const lum = duplicateM(M);

    const perm: number[] = Array(nRows).fill(0); // set up row permutation result

    for (let i = 0; i < nRows; ++i) {
        perm[i] = i;
    }

    let toggle = 1; // toggle tracks row swaps.
    // +1 -greater-than even, -1 -greater-than odd. used by MatrixDeterminant

    for (
        let j = 0;
        j < nRows - 1;
        ++j // each column
    ) {
        let colMax = Math.abs(lum[j][j]); // find largest val in col
        let pRow = j;

        // reader Matt V needed this:
        for (let i = j + 1; i < nRows; ++i) {
            if (Math.abs(lum[i][j]) > colMax) {
                colMax = Math.abs(lum[i][j]);
                pRow = i;
            }
        }
        // Not sure if this approach is needed always, or not.

        if (pRow !== j) {
            // if largest value not on pivot, swap rows
            const rowPtr = [...lum[pRow]];
            lum[pRow] = [...lum[j]];
            lum[j] = rowPtr;

            const tmp = perm[pRow]; // and swap perm info
            perm[pRow] = perm[j];
            perm[j] = tmp;

            toggle = -toggle; // adjust the row-swap toggle
        }

        // --------------------------------------------------
        // This part added later (not in original)
        // and replaces the 'return null' below.
        // if there is a 0 on the diagonal, find a good row
        // from i = j+1 down that doesn't have
        // a 0 in column j, and swap that good row with row j
        // --------------------------------------------------

        if (Math.abs(lum[j][j]) < Number.EPSILON) {
            // find a good row to swap
            let goodRow = -1;
            for (let row = j + 1; row < nRows; ++row) {
                if (Math.abs(lum[row][j]) > Number.EPSILON) goodRow = row;
            }

            if (goodRow === -1) throw new Error("Cannot use Doolittle's method");

            // swap rows so 0.0 no longer on diagonal
            var rowPtr = [...lum[goodRow]];
            lum[goodRow] = [...lum[j]];
            lum[j] = rowPtr;

            const tmp = perm[goodRow]; // and swap perm info
            perm[goodRow] = perm[j];
            perm[j] = tmp;

            toggle = -toggle; // adjust the row-swap toggle
        }
        // --------------------------------------------------
        // if diagonal after swap is zero . .
        //if (Math.Abs(result.mat[j,j]) less-than 1.0E-20)
        //  return null; // consider a throw

        for (let i = j + 1; i < nRows; ++i) {
            lum[i][j] /= lum[j][j];
            for (let k = j + 1; k < nRows; ++k) {
                lum[i][k] -= lum[i][j] * lum[j][k];
            }
        }
    }

    return { perm, lum };
};

const solveLU = (LU: MatMxN, b: number[]): number[] => {
    // before calling this helper, permute b using the perm array
    // from MatrixDecompose that generated luMatrix
    const n = rows(LU);
    const result: number[] = [...b];

    for (let i = 1; i < n; ++i) {
        let sum = result[i];
        for (let j = 0; j < i; ++j) sum -= LU[i][j] * result[j];
        result[i] = sum;
    }

    result[n - 1] /= LU[n - 1][n - 1];
    for (let i = n - 2; i >= 0; --i) {
        let sum = result[i];
        for (let j = i + 1; j < n; ++j) sum -= LU[i][j] * result[j];
        result[i] = sum / LU[i][i];
    }

    return result;
};

export const toPoint = (ptx: number, pty: number, width: number, height: number): Point => {
    const max = Math.max(width, height);
    const x = (ptx - width / 2) / max;
    const y = (pty - height / 2) / max;
    return {
        x: x,
        y: y,
    };
};
