import FirebaseSource from '../../data/source/FirebaseSource';

export const creditProviders = {
    addCredit: {
        provider: ({ firebaseSource }: { firebaseSource: FirebaseSource }) => firebaseSource.addCredit,
        single: false,
    },
    getCredits: {
        provider: ({ firebaseSource }: { firebaseSource: FirebaseSource }) => firebaseSource.getCredits,
        single: false,
    },
};
