import { createBrowserRouter } from 'react-router-dom';
import { routes } from './routes';
import { isValidUrl } from './utils/url';

const router = createBrowserRouter(routes);

export const routingProvider = {
    navigate: (path: string) => {
        if (isValidUrl(path)) {
            const paths = path.split('vivant://');
            if (paths.length > 1) {
                router.navigate(`/${paths[1]}`);
            } else {
                window.open(path);
            }
        } else {
            router.navigate(path);
        }
    },
    navigateReplace: (nav: string) => router.navigate(nav, { replace: true }),
    navigateUp: () => router.navigate(-1),
};

export default router;
