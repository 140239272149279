export const isOS = () => {
    const platform = navigator?.platform;
    const isIOS = /iPad|iPhone|iPod/.test(platform) || (platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    return isIOS;
};

export const isAndroid = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('android') > -1;
};
