export const clamp = (n: number, min: number, max: number) => Math.max(min, Math.min(n, max));

export const abs = (n: number) => Math.abs(n);

export type Point = { x: number; y: number };

export const TopLeft = { x: -1, y: 1 };
export const TopCenter = { x: 0, y: 1 };
export const TopRight = { x: 1, y: 1 };
export const MidCenter = { x: 0, y: 0 };
export const BotLeft = { x: -1, y: -1 };
export const BotCenter = { x: 0, y: -1 };
export const BotRight = { x: 1, y: -1 };

export const dif = (pt1: Point, pt2: Point): Point => ({
    x: pt1.x - pt2.x,
    y: pt1.y - pt2.y,
});
export const sum = (pt1: Point, pt2: Point): Point => ({
    x: pt1.x + pt2.x,
    y: pt1.y + pt2.y,
});

export const mul = (pt: Point, n: number) => ({ x: pt.x * n, y: pt.y * n });

export const dist2 = (pt1: Point, pt2: Point): number =>
    (pt1.x - pt2.x) * (pt1.x - pt2.x) + (pt1.y - pt2.y) * (pt1.y - pt2.y);
export const dist = (pt1: Point, pt2: Point) => Math.sqrt(dist2(pt1, pt2));

export const int = (n: number) => Math.floor(n);
export const round = (n: number) => Math.round(n);
export const uint = (n: number) => Math.floor(n);
export const max = (n1: number, n2: number) => Math.max(n1, n2);
export const cnt = (value: number) => Math.floor(Math.abs(value)) * Math.sign(value);
