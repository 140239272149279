import { injector } from '../arch/Injector';
import router, { routingProvider } from '../../router';
import FirebaseSource from '../../data/source/FirebaseSource';
import { storyProviders } from './storyProviders';
import { isAndroid, isOS } from '../../utils/platform';
import { prizeProviders } from './prizeProviders';
import { userProviders } from './userProviders';
import { messagingProviders } from './messagingProviders';
import { permissionsProviders } from './permissionsProvider';
import { creditProviders } from './creditProviders';
import { filesProviders } from './filesProvider';

const lensInjector = injector({
    firebaseSource: {
        provider: () => new FirebaseSource(),
        single: true,
    },
    navigate: {
        provider: () => routingProvider.navigate,
        single: false,
    },
    navigateUp: {
        provider: () => routingProvider.navigateUp,
        single: false,
    },
    navigateReplace: {
        provider: () => routingProvider.navigateReplace,
        single: false,
    },
    openUrl: {
        provider: () => (url: string) => window.open(url, '_new'),
        single: false,
    },
    isIos: {
        provider: () => () => isOS,
        single: false,
    },
    isAndroid: {
        provider: () => () => isAndroid,
        single: false,
    },
    setReminder: {
        provider: ({ firebaseSource }: FirebaseSourceProps) => firebaseSource.setReminder,
        single: false,
    },
    ...storyProviders,
    ...prizeProviders,
    ...userProviders,
    ...messagingProviders,
    ...permissionsProviders,
    ...creditProviders,
    ...filesProviders,
});

export const env = lensInjector as any;

export interface RepoProviderProps {
    firebaseSource: FirebaseSource;
    getUserId: () => Promise<string | undefined>;
    getAnonId: () => Promise<string | undefined>;
    getIsGuest: () => Promise<boolean>;
}

export type FirebaseSourceProps = { firebaseSource: FirebaseSource };

export { lensInjector };
