const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const MONTHY = DAY * 30;

const delay = async (seconds?: number) => {
    if (seconds) {
        await new Promise((resolve) => setTimeout(resolve, seconds * 1000));
    }
};

const toShortDateText = (millis?: number | null) => {
    if (!millis) return null;
    const options: Intl.DateTimeFormatOptions = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    };

    return new Intl.DateTimeFormat('en-US', options).format(new Date(millis));
};

export const toShortTimeText = (millis?: number | null) => {
    if (!millis) return null;
    const now = new Date().getTime();

    let options: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: '2-digit',
    };

    const dif = Math.abs(now - millis);

    if (dif > DAY) {
        options = {
            ...options,
            weekday: 'short',
        };
    }

    if (dif > WEEK) {
        options = {
            ...options,
            day: 'numeric',
            month: 'short',
        };
    }

    if (dif > MONTHY) {
        options = {
            ...options,
            year: 'numeric',
        };
    }

    return new Intl.DateTimeFormat('en-US', options).format(new Date(millis));
};

const time = () => new Date().getTime();

export { toShortDateText, delay, time };
