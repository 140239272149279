import { Color } from '../../types/Color.types';
import { clamp } from '../../utils/math';

export const toInt = (r: number, g: number, b: number, a?: number) => {
    r = clamp(r, 0, 255);
    g = clamp(g, 0, 255);
    b = clamp(b, 0, 255);
    a = clamp(a ?? 0, 0, 100);
    return ((r * 256 + g) * 256 + b) * 101 + a;
};

export const colorToInt = (c: Color) => {
    return toInt(c.red ?? 0, c.green ?? 0, c.blue ?? 0, (100 * (c.alpha ?? 0)) / 255);
};

export const toColor = (i: number): Color => {
    const alpha = i % 101;
    i = Math.floor(i / 101);
    const blue = i % 256;
    i = Math.floor(i / 256);
    const green = i % 256;
    i = Math.floor(i / 256);
    const red = i % 256;
    return { red, green, blue, alpha: Math.round((255 * alpha) / 100) };
};

export const IntColor = {
    black: toInt(0, 0, 0),
    red: toInt(255, 0, 0),
    blue: toInt(0, 0, 255),
    green: toInt(0, 255, 0),
    white: toInt(255, 255, 255),
    primary: toInt(100, 75, 150),
};

export const toStringRgb = (color: number) => {
    const { red: r, green: g, blue: b } = toColor(color);
    return `rgb(${r}, ${g}, ${b})`;
};
