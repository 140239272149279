const toArray = <K extends string | number, V>(record: Record<K, V>): V[] => {
    if (!record) return [];
    const keys = Object.keys(record);
    if (!keys.length) return [];
    keys.sort((a, b) => (a > b ? 1 : -1));

    const v: V[] = [];
    const unindexed: string[] = [];

    keys.forEach((key) => {
        const index = parseInt(key);
        const extr = key as Extract<K, string>;
        if (!isNaN(index)) {
            v[index] = record[extr];
        } else {
            unindexed.push(key);
        }
    });

    unindexed.forEach((key) => {
        const extr = key as Extract<K, string>;
        v.push(record[extr]);
    });

    return v;
};

export const toRecord = <K extends string | number | symbol, V, A>(
    array: A[],
    mapper: (item: A) => [K, V]
): Record<K, V> => {
    return array.reduce((prev, cur) => {
        const [key, value] = mapper(cur);
        prev = { ...prev, [key]: value };
        return prev;
    }, {} as Record<K, V>);
};

export const findByKey = <O extends {}>(recordObject: O, key: string) => {
    return (recordObject as Record<string, unknown>)[key];
};

export { toArray };
