import { CssBaseline, ThemeProvider } from '@mui/material';
import { PropsWithChildren, useEffect, useState } from 'react';
import { darkTheme, lightTheme } from './theme';
import { Color } from '../../types/Color.types';
import { toColor } from '../uiutils/color';

const AppTheme = ({ children }: PropsWithChildren) => {
    const [isDark, setIsDark] = useState<boolean>(
        window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    );

    useEffect(() => {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
            if (isDark !== e.matches) setIsDark(e.matches);
        });
    }, [isDark]);

    return (
        <ThemeProvider theme={useColorTheme(isDark)}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
};

export const getIsDark = (backColor: number | Color): boolean => {
    const color = typeof backColor === 'number' ? toColor(backColor) : backColor;
    const Normal = 1 / 255;

    // 0.299 ∙ Red + 0.587 ∙ Green + 0.114 ∙ Blue

    const avg = (color.red ?? 0) * 0.3 * Normal + (color.green ?? 0) * 0.6 * Normal + (color.blue ?? 0) * 0.1 * Normal;

    return avg < 0.5;
};

export const useColorTheme = (isDark: boolean) => {
    return isDark ? darkTheme : lightTheme;
};

export default AppTheme;
