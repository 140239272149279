import PermissionsRepo from '../../data/permissions/PermissionsRepo';
import FirebaseSource from '../../data/source/FirebaseSource';

export const permissionsProviders = {
    getPermissionsResult: {
        provider: ({ permissionsRepo }: { permissionsRepo: PermissionsRepo }) => permissionsRepo.getPermissionsResult,
        single: false,
    },
    setPermissionsRequest: {
        provider: ({ permissionsRepo }: { permissionsRepo: PermissionsRepo }) => permissionsRepo.setPermissionsRequest,
        single: false,
    },
    cancelPermissionsRequest: {
        provider: ({ permissionsRepo }: { permissionsRepo: PermissionsRepo }) =>
            permissionsRepo.cancelPermissionsRequest,
        single: false,
    },
    setAllowPermissions: {
        provider: ({ permissionsRepo }: { permissionsRepo: PermissionsRepo }) => permissionsRepo.setAllowPermissions,
        single: false,
    },
    setDenyPermissions: {
        provider: ({ permissionsRepo }: { permissionsRepo: PermissionsRepo }) => permissionsRepo.setDenyPermissions,
        single: false,
    },
    permissionsRepo: {
        provider: ({ firebaseSource }: { firebaseSource: FirebaseSource }) => new PermissionsRepo(firebaseSource),
        single: true,
    },
};
